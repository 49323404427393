<template>
  <div class="realName">
    <div class="title">个人实名</div>
    <div class="tips" v-if="$store.state.userInfo.personal_auth == 0">请填写您的信息，完成实名认证</div>
    <div class="success_tags" v-if="$store.state.userInfo.personal_auth == 1">您已通过审核</div>
    <div class="tips" v-if="$store.state.userInfo.personal_auth == 2">系统审核中。。。</div>
    <div class="error_tags" v-if="$store.state.userInfo.personal_auth == 3">系统审核失败：{{ personalInfo.refuse_reason }}。</div>
    <div class="nameItem">
      <label>请上传身份证正面照片</label>
      <span>保持身份证完整清晰</span><br>
      <uploadImage stats="id_before_image" @success="uploadSuccess">
        <IImage :src="personalInfo.id_before_image" alt="" slot="uploadContent" v-if="personalInfo.id_before_image" />
        <img src="@/assets/img/person/ID_card_front.png" alt="" slot="uploadContent" v-else>
      </uploadImage>
    </div>
    <div class="nameItem">
      <label>请上传身份证反面照片</label>
      <span>保持身份证完整清晰</span><br>
      <uploadImage stats="id_back_image" @success="uploadSuccess">
        <IImage :src="personalInfo.id_back_image" alt="" slot="uploadContent" v-if="personalInfo.id_back_image"></IImage>
        <img src="@/assets/img/person/ID_card_back.png" alt="" slot="uploadContent" v-else>
      </uploadImage>
    </div>
    <div class="nameItem">
      <label>完善个人信息</label>
      <div class="nameID">
        <div class="text">姓名：</div>
        <input type="text" placeholder="请输入姓名" v-model="personalInfo.name">
      </div>
      <div class="nameID">
        <div class="text">身份证号：</div>
        <input type="text" placeholder="请输入身份证号" v-model="personalInfo.id_num">
      </div>
    </div>
    <button class="submit" @click="submit">提交认证</button>
  </div>
</template>
  
<script>
import uploadImage from '../../../components/uploadImage/uploadImage.vue';
import mixin from '../../../mixin';
export default {
  mixins: [mixin],
  components: {
    uploadImage
  },
  data () {
    return {
      personalInfo: {
        id_before_image: '',
        id_back_image: '',
        id_num: '',
        name: '',
      }
    }
  },
  methods: {
    uploadSuccess (val) {
      this.personalInfo[val.status] = val.img;
    },
    getPersonalInfo () {
      this.$api.getPersonalInfo().then(res => {
        console.log(res, '当前实名认证');
        if (res.data.name) {
          this.personalInfo = res.data;
        }
      })
    },
    submit () {
      if (!this.personalInfo.id_back_image || !this.personalInfo.id_before_image) {
        this.$message.error('请上传身份证照片');
        return;
      }
      if (!this.$regs.isIdCard.test(this.personalInfo.id_num)) {
        this.$message.error('身份证格式不正确');
        return;
      }
      if (!this.$regs.realName.test(this.personalInfo.name)) {
        this.$message.error('请输入正确的名字');
        return;
      }
      console.log(this.personalInfo);
      this.$api.personalAuth(this.personalInfo).then(res => {
        if (res.code == 1) {
          this.$message.success(res.msg);
          setTimeout(() => {
            location.reload();
          }, 2000)
        }
      })
    }
  },
  created () {
    this.getPersonalInfo();
  },
}
</script>
  
<style lang="scss" >
.realName {
  background: #ffffff;
  border-radius: 8px 8px 8px 8px;

  .title {
    font-size: 22px;
    font-weight: 500;
    color: #2f2f2f;
    line-height: 26px;
    margin: 0 25px 0px 9px;
    padding: 18px 10px;
    border-bottom: 1px solid #e5e5e5;
  }
  .tips,
  .error_tags,
  .success_tags {
    padding: 18px;
    font-size: 12px;
    font-weight: 500;
    color: #3f63ff;
    line-height: 14px;
    margin: 0px 25px 5px 9px;
    background: #ebeef7;
  }
  .error_tags {
    color: #ff4e4e;
    background: #f7e4e4;
  }
  .success_tags {
    background: #e3f0e2;
    color: #379e41;
  }
  .nameItem {
    margin: 20px 35px;

    label {
      font-size: 18px;
      font-weight: 500;
      color: #333333;
      line-height: 20px;
      margin-bottom: 7px;
      display: block;
    }

    span {
      font-size: 12px;
      font-weight: 400;
      color: #666666;
      line-height: 16px;
    }

    img {
      width: 270px;
      height: 173px;
      border-radius: 11px 11px 11px 11px;
      margin: 10px 0 0 33px;
    }

    .nameID {
      display: flex;
      align-items: center;
      margin-bottom: 11px;

      .text {
        width: 70px;
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        line-height: 17px;
        text-align: right;
      }

      input {
        width: 378px;
        height: 44px;
        background: #ffffff;
        border-radius: 5px 5px 5px 5px;
        border: 1px solid #e5e5e5;
        padding: 13px;

        &::placeholder {
          font-size: 14px;
          font-weight: 400;
          color: #999999;
          line-height: 17px;
        }
      }
    }
  }

  .submit {
    padding: 11px 143px;
    font-size: 18px;
    font-weight: 500;
    color: #ffffff;
    line-height: 18px;
    background: #3f63ff;
    border-radius: 10px 10px 10px 10px;
    margin: 58px 0 69px 33px;
    cursor: pointer;
  }
}
</style>